import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './SignUpForm.styl';

import { required, numberByCode, requiredNumber, isEmail } from '../../../../../utils/validators';

import InputField from '../../../../../components/InputField';
import PhoneField from '../../../../../components/PhoneField';
import CheckboxField from '../../../../../components/CheckboxField';
import Button from '../../../../../components/Button';
import { landingRegistration } from '../../../../../state/modules/user/actions';
import { setGlobalMessage } from '../../../../../utils/setGlobalMessage';

const cx = classNames.bind(styles);

const SignUpForm = ({ onSuccessCallback }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, setError } = useForm();
  const city = useSelector(state => state.city.userCity._supertag);
  const yandexMetrika = useSelector(state => state.city.userCity.yandex_metrika);

  const userAgreementLink = `/${city}/user-agreement`;
  const policyAgreementLink = `/${city}/personal-data-processing-policy`;

  const onFailureCallback = useCallback(
    (isExisting, param, message) => {
      if (isExisting) {
        return setError(param, {
          type: 'manual',
          message: intl.formatMessage({
            id: param === 'phone' ? 'signUp.existPhone' : 'signUp.existEmail',
          }),
        });
      }

      return setError(param, {
        type: 'manual',
        message,
      });
    },
    [setError, intl],
  );

  const onSubmit = useCallback(
    async data => {
      const onSuccess = () => {
        onSuccessCallback?.();
        setGlobalMessage(intl.formatMessage({ id: 'signUp.successMessage.text' }), {
          type: 'success',
        });
        if (yandexMetrika) {
          window.ym(yandexMetrika, 'reachGoal', `form-send-${city}`);
        }
      };

      const generatedPassword = Math.random().toString(36).slice(2);
      dispatch(
        landingRegistration(
          { ...data, password: generatedPassword, password_repeat: generatedPassword },
          onFailureCallback,
          onSuccess,
        ),
      );
    },
    [dispatch, landingRegistration, onFailureCallback, onSuccessCallback],
  );

  return (
    <>
      <div className={cx('SignUpForm__field')}>
        <InputField
          name="firstName"
          register={register({ validate: { required } })}
          label={intl.formatMessage({ id: 'field.first_name.label' })}
          placeholder={`${intl.formatMessage({ id: 'field.username.placeholder' })}*`}
        />
        {errors.firstName?.message ? (
          <span className={cx('SignUpForm__error')}>{errors.firstName?.message}</span>
        ) : (
          <span className={cx('SignUpForm__error_hidden')}>Ok</span>
        )}
      </div>
      <div className={cx('SignUpForm__field')}>
        <InputField
          name="email"
          register={register({ validate: { required, isEmail } })}
          label={intl.formatMessage({ id: 'field.email.label.full' })}
          placeholder={intl.formatMessage({ id: 'field.email.example' })}
        />
        {errors.email?.message ? (
          <span className={cx('SignUpForm__error')}>{errors.email?.message}</span>
        ) : (
          <span className={cx('SignUpForm__error_hidden')}>Ok</span>
        )}
      </div>
      <div className={cx('SignUpForm__field')}>
        <Controller
          name="phone"
          control={control}
          rules={{ validate: { required, requiredNumber, numberByCode } }}
          render={({ onChange, value, name }) => (
            <PhoneField
              name={name}
              value={value}
              onChange={onChange}
              label={intl.formatMessage({ id: 'field.phone.label2' })}
            />
          )}
        />
        {errors.phone?.message ? (
          <span className={cx('SignUpForm__error')}>{errors.phone?.message}</span>
        ) : (
          <span className={cx('SignUpForm__error_hidden')}>Ok</span>
        )}
      </div>

      <div className={cx('SignUpForm__checkbox-group')}>
        <Controller
          name="subscription_state"
          defaultValue={true}
          control={control}
          render={({ onChange, value, name }) => (
            <CheckboxField
              className={cx('SignUpForm__checkbox')}
              name={name}
              value={value}
              checked={value}
              onChange={e => onChange(e.target.checked)}
              label={intl.formatMessage({ id: 'page.landing.email.agreement' })}
            />
          )}
        />
        <Controller
          name="sms_state"
          defaultValue={true}
          control={control}
          render={({ onChange, value, name }) => (
            <CheckboxField
              className={cx('SignUpForm__checkbox')}
              name={name}
              value={value}
              checked={value}
              onChange={e => onChange(e.target.checked)}
              label={intl.formatMessage({ id: 'page.landing.sms.agreement' })}
            />
          )}
        />
      </div>
      <div className={cx('SignUpForm__footer')}>
        <Button onClick={handleSubmit(onSubmit)} className={cx('SignUpForm__footer_button')}>
          {intl.formatMessage({ id: 'page.landing.signup' })}
        </Button>
        <div className={cx('SignUpForm__footer_text')}>
          {intl.formatMessage({ id: 'page.landing.policy.send' })}
          <Link to={userAgreementLink} target="_blank">
            {intl.formatMessage({ id: 'page.landing.user.agreement' })}
          </Link>
          {intl.formatMessage({ id: 'page.landing.policy.and' })}
          <Link to={policyAgreementLink} target="_blank">
            {intl.formatMessage({ id: 'page.landing.policy.agreement' })}
          </Link>
        </div>
      </div>
    </>
  );
};

SignUpForm.propTypes = {
  onSuccessCallback: PropTypes.func.isRequired,
};

export default SignUpForm;
